// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

// ** Axios Imports
import axios from 'axios'

export const getAllTraining = createAsyncThunk('training/getAllTraining', async () => {
    const response = await axios.get('/schedules/training')
    return response.data
})

export const getAllHistory = createAsyncThunk('training/getAllHistory', async () => {
  const response = await axios.get('/schedules/history')
  return response.data
})

export const getFile = createAsyncThunk('training/getFile', async (file) => {
  const response = await axios.post(`/booking/file`, file, { responseType: 'blob' })
  const fileData = response.data
  // Buat objek URL untuk file
  const fileUrl = URL.createObjectURL(fileData)
  return fileUrl
})

export const getPersons = createAsyncThunk('training/getPersons', async (id) => {
  const response = await axios.get(`/training/persons/${id}`)
  return response.data
})

export const getItem = createAsyncThunk('training/getItem', async (id) => {
  const response = await axios.get(`/training/${id}`)
  return response.data
})

export const addData = createAsyncThunk(
  'training/addData',
  async (schedule, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post('/training', schedule)
      if (schedule.category_id === 1) {
        await dispatch(getAllRent())
      } else if (schedule.category_id === 2) {
        await dispatch(getAllPPC())
      } else if (schedule.category_id === 3) {
        await dispatch(getAllType())
      } else if (schedule.category_id === 4) {
        await dispatch(getAllDiff())
      }
      return response.data
    } catch (error) {
      if (error.response && error.response.status === 400) {
        // Mengambil pesan kesalahan dari respons JSON jika status kode 400
        const errorMessage = error.response.data
        return rejectWithValue(errorMessage)
      } else {
        throw error // Melanjutkan melempar kesalahan untuk penanganan umum
      }
    }
  }
)

export const updateData = createAsyncThunk(
  'training/updateData',
  async (schedule, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.put(`/training/${schedule.id}`, schedule)
      if (schedule.category_id === 1) {
        await dispatch(getAllRent())
      } else if (schedule.category_id === 2) {
        await dispatch(getAllPPC())
      } else if (schedule.category_id === 3) {
        await dispatch(getAllType())
      } else if (schedule.category_id === 4) {
        await dispatch(getAllDiff())
      }
      return response.data
    } catch (error) {
      if (error.response && error.response.status === 400) {
        // Mengambil pesan kesalahan dari respons JSON jika status kode 409
        const errorMessage = error.response.data
        return rejectWithValue(errorMessage)
      } else {
        throw error // Melanjutkan melempar kesalahan untuk penanganan umum
      }
    }
  }
)

export const deleteData = createAsyncThunk(
    'training/deleteData',
    async ({ id, category_id }, { dispatch }) => {
        const response = await axios.delete(`/training/${id}`)
        if (category_id === 1) {
          await dispatch(getAllRent())
        } else if (category_id === 2) {
          await dispatch(getAllPPC())
        } else if (category_id === 3) {
          await dispatch(getAllType())
        } else if (category_id === 4) {
          await dispatch(getAllDiff())
        }
        return response.data
    })


export const dataSlice = createSlice({
    name: 'training',
    initialState: {
        results: [],
        result: null,
        persons: null,
        selected: null,
        file: null,
        isLoading: false
    },
    reducers: {
      selectData: (state, action) => {
            if (action.payload === null) {
                state.selected = null
            } else {
                state.selected = action.payload
            }
        }
    },
    extraReducers: (builder) => {
      builder
        .addCase(getAllTraining.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getAllTraining.fulfilled, (state, action)  => {
          state.isLoading = false
          state.results = action.payload
        })
        .addCase(getAllHistory.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getAllHistory.fulfilled, (state, action)  => {
          state.isLoading = false
          state.results = action.payload
        })
        .addCase(addData.pending, (state) => {
          state.isLoading = true
        })  
        .addCase(addData.rejected, (state, action) => {
          state.isLoading = false
          MySwal.fire({
            title: 'Failed!',
            html: `Add schedule failed.<br>${action.payload.msg}`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
        .addCase(addData.fulfilled, (state) => {
          state.isLoading = false
          MySwal.fire({
            title: 'Success!',
            text: 'Add schedule successfully',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })  
        })
        .addCase(updateData.pending, (state) => {
          state.isLoading = true
        })  
        .addCase(updateData.rejected, (state, action) => {
          state.isLoading = false
          MySwal.fire({
            title: 'Failed!',
            html: `Update schedule failed.<br>${action.payload.msg}`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })  
        })
        .addCase(updateData.fulfilled, (state) => {
          state.isLoading = false
          MySwal.fire({
            title: 'Success!',
            text: 'Update schedule successfully',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
        .addCase(deleteData.rejected, () => {
          MySwal.fire({
            title: 'Failed!',
            text: 'Delete schedule failed',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
        .addCase(deleteData.fulfilled, () => {
          MySwal.fire({
            title: 'Success!',
            text: 'Delete data successfully',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
        .addCase(getFile.pending, (state) => {
          state.file = null
          state.isLoading = true
        })
        .addCase(getFile.fulfilled, (state, action)  => {
          state.isLoading = false
          state.file = action.payload
        })
        .addCase(getPersons.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getPersons.fulfilled, (state, action)  => {
          state.isLoading = false
          state.persons = action.payload
        })
        .addCase(getItem.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getItem.fulfilled, (state, action)  => {
          state.isLoading = false
          state.result = action.payload
        })
    }
})
export const { selectData } = dataSlice.actions
export default dataSlice.reducer
