// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

// ** Axios Imports
import axios from 'axios'

export const getAllRent = createAsyncThunk('registration/getAllRent', async () => {
    const response = await axios.get('/registration/rent')
    return response.data
})

export const getAllPPC = createAsyncThunk('registration/getAllPPC', async () => {
  const response = await axios.get('/registration/ppc')
  return response.data
})

export const getAllType = createAsyncThunk('registration/getAllType', async () => {
  const response = await axios.get('/registration/type')
  return response.data
})

export const getAllDiff = createAsyncThunk('registration/getAllDiff', async () => {
  const response = await axios.get('/registration/diff')
  return response.data
})

export const getItem = createAsyncThunk('registration/getItem', async (id) => {
  const response = await axios.get(`/registration/${id}`)
  return response.data
})

export const getTime = createAsyncThunk('registration/getTime', async (data) => {
  const response = await axios.post(`/booking/time`, data)
  return response.data
})

export const addData = createAsyncThunk(
  'registration/addData',
  async (booking, { rejectWithValue }) => {
    try {
      const response = await axios.post('/booking', booking)
      return response.data
    } catch (error) {
      if (error.response && error.response.status === 400) {
        // Mengambil pesan kesalahan dari respons JSON jika status kode 400
        const errorMessage = error.response.data
        return rejectWithValue(errorMessage)
      } else {
        throw error // Melanjutkan melempar kesalahan untuk penanganan umum
      }
    }
  }
)

export const dataSlice = createSlice({
    name: 'registration',
    initialState: {
        results: [],
        timeSlots: [],
        result: null,
        selected: null,
        booking: {},
        persons: [],
        quantity: 1,
        category: null,
        simulator: null,
        isLoading: false,
        totalHours: 0,
        regtype: null,
        bookingTimes: [],
        deviceOptions: [
          {
            value: 1,
            label: 'Full Flight Simulator (FFS)'
          },
          {
            value: 2,
            label: 'Flight Training Device (FTD)'
          }
        ],
        registrationOptions: [
          {
            value: 'Company',
            label: 'Company'
          },
          {
            value: 'Personal',
            label: 'Personal'
          }
        ],
        leasedOptions: [
          {
            value: 1,
            label: 'Wet Leased'
          },
          {
            value: 2,
            label: 'Dry Leased'
          }
        ],
        personOptions: [
          {
            value: 1,
            label: '1 Person'
          },
          {
            value: 2,
            label: '2 Person'
          }
        ],
        trainingOptions: [
          {
            value: 2,
            label: 'Pilot Proficiency Check (PPC)'
          },
          {
            value: 3,
            label: 'Initial Type Rating'
          },
          {
            value: 4,
            label: 'Differences'
          }
        ]
    },
    reducers: {
      selectData: (state, action) => {
            if (action.payload === null) {
                state.selected = null
            } else {
                state.selected = action.payload
            }
        },
        addPerson: (state, action) => {
          const { persons } = action.payload
          return { ...state, persons }
        },
        updateQuantity: (state, action) => {
          const { quantity } = action.payload
          return { ...state, quantity }
        },
        updateRegtype: (state, action) => {
          const { type } = action.payload
          return { ...state, regtype: type }
        },
        updateCategory: (state, action) => {
          const { category } = action.payload
          return { ...state, category }
        },
        updateSimulator: (state, action) => {
          const { simulator } = action.payload
          return { ...state, simulator }
        },
        updateHours: (state, action) => {
          const { bookingTimes, totalHours } = action.payload
          return { ...state, bookingTimes, totalHours  }
        },
        resetBooking: (state) => {
          return { ...state, booking: {}, persons: [], quantity: null }
        }
    
    },
    extraReducers: (builder) => {
      builder
        .addCase(getAllRent.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getAllRent.fulfilled, (state, action)  => {
          state.isLoading = false
          state.results = action.payload
        })
        .addCase(getAllPPC.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getAllPPC.fulfilled, (state, action)  => {
          state.isLoading = false
          state.results = action.payload
        })
        .addCase(getAllType.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getAllType.fulfilled, (state, action)  => {
          state.isLoading = false
          state.results = action.payload
        })
        .addCase(getAllDiff.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getAllDiff.fulfilled, (state, action)  => {
          state.isLoading = false
          state.results = action.payload
        })
        .addCase(getItem.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getItem.fulfilled, (state, action)  => {
          state.isLoading = false
          state.result = action.payload
        })
        .addCase(getTime.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getTime.fulfilled, (state, action)  => {
          state.isLoading = false
          state.timeSlots = action.payload
        })
        .addCase(addData.pending, (state) => {
          state.isLoading = true
        })  
        .addCase(addData.rejected, (state, action) => {
          state.isLoading = false
          MySwal.fire({
            title: 'Failed!',
            html: `${action.payload.msg}`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
        .addCase(addData.fulfilled, (state) => {
          state.isLoading = false
          MySwal.fire({
            title: 'Success!',
            html: 'Terima kasih, pendaftaran anda akan diverifikasi oleh admin dalam 1x24 jam.<br> Silahkan cek email secara berkala.<br>Silahkan hubungi admin +62 877-7822-9661',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
    }
})
export const { selectData, updateQuantity, resetBooking, addPerson, updateCategory, updateSimulator, updateHours, updateRegtype } = dataSlice.actions
export default dataSlice.reducer
