// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

// ** Axios Imports
import axios from 'axios'

export const getAllDry = createAsyncThunk('booking/getAllDry', async () => {
    const response = await axios.get('/booking/dry')
    return response.data
})

export const getAllWet = createAsyncThunk('booking/getAllWet', async () => {
  const response = await axios.get('/booking/wet')
  return response.data
})

export const getFile = createAsyncThunk('booking/getFile', async (file) => {
  const response = await axios.post(`/booking/file`, file, { responseType: 'blob' })
  const fileData = response.data
  // Buat objek URL untuk file
  const fileUrl = URL.createObjectURL(fileData)
  return fileUrl
})

export const getAllInstructor = createAsyncThunk('booking/getAllInstructor', async () => {
  const response = await axios.get('/instructors')
  return response.data
})

export const verifyData = createAsyncThunk(
  'booking/verifyData',
  async (data, { dispatch }) => {
    console.log('ss')
      const response = await axios.patch(`/booking/${data.id}`, data)
      if (data.category_id === 1) {
        await dispatch(getAllDry())
      } else {
        await dispatch(getAllWet())
      }
      return response.data
  }
)

export const addPrice = createAsyncThunk(
  'booking/addPrice',
  async ({ id, price, type }, { dispatch }) => {
      const response = await axios.patch(`/booking/price/${id}`, { price })
      if (type === 1) {
        await dispatch(getAllDry())
      } else {
        await dispatch(getAllWet())
      }
      return response.data
  }
)

export const addSchedule = createAsyncThunk(
  'booking/addSchedule',
  async (schedule, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post('/schedules', schedule)
      if (schedule.category_id === 1) {
        await dispatch(getAllDry())
      } else  {
        await dispatch(getAllWet())
      }
      return response.data
    } catch (error) {
      if (error.response && error.response.status === 400) {
        // Mengambil pesan kesalahan dari respons JSON jika status kode 400
        const errorMessage = error.response.data
        return rejectWithValue(errorMessage)
      } else {
        throw error // Melanjutkan melempar kesalahan untuk penanganan umum
      }
    }
  }
)

export const getPersons = createAsyncThunk('booking/getPersons', async (id) => {
  const response = await axios.get(`/booking/persons/${id}`)
  return response.data
})

export const dataSlice = createSlice({
    name: 'booking',
    initialState: {
        results: [],
        result: null,
        selected: null,
        file: null,
        simulatorOptions: [],
        instructorOptions: [],
        isLoading: false
    },
    reducers: {
      selectData: (state, action) => {
            if (action.payload === null) {
                state.selected = null
            } else {
                state.selected = action.payload
            }
        }
    },
    extraReducers: (builder) => {
      builder
        .addCase(getAllDry.pending, (state) => {
          state.isLoading = true
          state.results = []
        })
        .addCase(getAllDry.fulfilled, (state, action)  => {
          state.isLoading = false
          state.results = action.payload
        })
        .addCase(getAllWet.pending, (state) => {
          state.isLoading = true
          state.results = []
        })
        .addCase(getAllWet.fulfilled, (state, action)  => {
          state.isLoading = false
          state.results = action.payload
        })
        .addCase(verifyData.pending, (state) => {
          state.isLoading = true
        })  
        .addCase(verifyData.rejected, (state, action) => {
          state.isLoading = false
          MySwal.fire({
            title: 'Failed!',
            html: `Verify booking failed.<br>${action.payload.msg}`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })  
        })
        .addCase(verifyData.fulfilled, (state) => {
          state.isLoading = false
          MySwal.fire({
            title: 'Success!',
            text: 'Verify booking successfully',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
        .addCase(addPrice.pending, (state) => {
          state.isLoading = true
        })  
        .addCase(addPrice.rejected, (state, action) => {
          state.isLoading = false
          MySwal.fire({
            title: 'Failed!',
            html: `Add price failed.<br>${action.payload.msg}`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })  
        })
        .addCase(addPrice.fulfilled, (state) => {
          state.isLoading = false
          MySwal.fire({
            title: 'Success!',
            text: 'Add price successfully',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
        .addCase(addSchedule.pending, (state) => {
          state.isLoading = true
        })  
        .addCase(addSchedule.rejected, (state, action) => {
          state.isLoading = false
          MySwal.fire({
            title: 'Failed!',
            html: `Add schedule failed.<br>${action.payload.msg}`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })  
        })
        .addCase(addSchedule.fulfilled, (state) => {
          state.isLoading = false
          MySwal.fire({
            title: 'Success!',
            text: 'Add schedule successfully',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
        .addCase(getFile.pending, (state) => {
          state.file = null
          state.isLoading = true
        })
        .addCase(getFile.fulfilled, (state, action)  => {
          state.isLoading = false
          state.file = action.payload
        })
        .addCase(getPersons.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getPersons.fulfilled, (state, action)  => {
          state.isLoading = false
          state.result = action.payload
        })
        .addCase(getAllInstructor.pending, (state) => {
          state.isLoading = true
          state.instructorOptions = []
        })
        .addCase(getAllInstructor.fulfilled, (state, action) => {
          state.isLoading = false
          state.instructorOptions = []
          action.payload.map(item => {
            state.instructorOptions.push({ value: item.id, label: item.name })
          })
        })
    }
})
export const { selectData } = dataSlice.actions
export default dataSlice.reducer
