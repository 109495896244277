// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

// ** Axios Imports
import axios from 'axios'

export const getItem = createAsyncThunk(
  'payment/getItem',
  async (payment, { rejectWithValue }) => {
    try {
      const response = await axios.post('/payment', payment)
      return response.data
    } catch (error) {
      if (error.response && error.response.status === 400) {
        // Mengambil pesan kesalahan dari respons JSON jika status kode 400
        const errorMessage = error.response.data
        return rejectWithValue(errorMessage)
      } else {
        throw error // Melanjutkan melempar kesalahan untuk penanganan umum
      }
    }
  }
)

export const updateData = createAsyncThunk(
  'payment/updateData',
  async (payment, { rejectWithValue }) => {
    try {
      const response = await axios.post('/payment/upload', payment)
      return response.data
    } catch (error) {
      if (error.response && error.response.status === 400) {
        // Mengambil pesan kesalahan dari respons JSON jika status kode 400
        const errorMessage = error.response.data
        return rejectWithValue(errorMessage)
      } else {
        throw error // Melanjutkan melempar kesalahan untuk penanganan umum
      }
    }
  }
)

export const dataSlice = createSlice({
    name: 'payment',
    initialState: {
        result: null,
        isLoading: false
    },
    extraReducers: (builder) => {
      builder
        .addCase(updateData.pending, (state) => {
          state.isLoading = true
        })  
        .addCase(updateData.rejected, (state, action) => {
          state.isLoading = false
          MySwal.fire({
            title: 'Failed!',
            html: `${action.payload.msg}`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
          state.result = null
        })
        .addCase(updateData.fulfilled, (state) => {
          state.isLoading = false
          MySwal.fire({
            title: 'Success!',
            html: 'Terima kasih, pembayaran anda akan diverifikasi oleh admin dalam 1x24 jam.<br> Silahkan cek email secara berkala.<br>Silahkan hubungi admin +62 877-7822-9661',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
          state.result = null
        })

        .addCase(getItem.pending, (state) => {
          state.isLoading = true
        })  
        .addCase(getItem.rejected, (state, action) => {
          state.isLoading = false
          MySwal.fire({
            title: 'Failed!',
            html: `${action.payload.msg}`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
        .addCase(getItem.fulfilled, (state, action) => {
          state.isLoading = false
          state.result = action.payload
        })
    }
})
export const { selectData, updateQuantity, resetBooking, addPerson } = dataSlice.actions
export default dataSlice.reducer