// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import user from '@src/views/users/store'
import categories from '@src/views/categories/store'
import simulators from '@src/views/simulators/store'
import instructors from '@src/views/instructors/store'
import schedules from '@src/views/schedules/store'
import registration from '@src/views/registration/store'
import booking from '@src/views/booking/store'
import training from '@src/views/training/store'
import dashboard from '@src/views/dashboard/store'
import landingpage from '@src/views/landing-page/store'
import payment from '@src/views/payment/store'


const rootReducer = {
  auth,
  navbar,
  layout,
  user,
  categories,
  simulators,
  instructors,
  schedules,
  registration,
  booking, 
  training, 
  dashboard,
  landingpage,
  payment
}

export default rootReducer
