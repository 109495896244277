// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const addVisitor = createAsyncThunk(
    'landingpage/addData',
    async () => {
        const response = await axios.get('/visitors')
        return response.data
    }
)

export const checkCatalog = createAsyncThunk(
    'landingpage/checkCatalog',
    async () => {
        const response = await axios.get('/catalog/check')
        return response.data
    }
)

export const dataSlice = createSlice({
    name: 'landingpage',
    initialState: {
        result: {},
        isLoading: false
    },
    extraReducers: {
        [checkCatalog.pending]: (state) => {
          state.isLoading = true
        },
        [checkCatalog.fulfilled]: (state, action) => {
          state.isLoading = false
          state.result = action.payload
        }
      }
})
export default dataSlice.reducer
