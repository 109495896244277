// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getTraining = createAsyncThunk('dashboard/getTraining', async () => {
  const response = await axios.get('/dashboard/training') //ganti dg api yg filter tasks berdasarkan assignTo user_id, sort dengan dueDate ASC
  return response.data
})

export const getStatistics = createAsyncThunk('dashboard/getStatistics', async () => {
  const response = await axios.get('/dashboard/statistics')
  return response.data
})

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    statistics: [],
    result: {},
    selected: null,
    isLoading: false
  },
  extraReducers: {
    [getTraining.pending]: (state) => {
      state.isLoading = true
    },
    [getTraining.fulfilled]: (state, action) => {
      state.isLoading = false
      state.result = action.payload
    },
    [getStatistics.pending]: (state) => {
      state.isLoading = true
    },
    [getStatistics.fulfilled]: (state, action) => {
      state.isLoading = false
      state.statistics = action.payload
    }
  }
})
export default dashboardSlice.reducer
